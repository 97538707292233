.LoginView {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;

  .card-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem;

    img {
      width: 8rem;
      height: auto;
    }
  }

  .card-body {
    padding: 0 3rem 3rem;
  }

  .btn-primary {
    min-height: 3rem;
  }
}