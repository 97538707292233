.navbar {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    --bs-navbar-padding-x: 1rem; 
    height: var(--navbar-height);
    background: var(--bs-white);
    .navbar-brand {
        text-transform: uppercase;
        // texto en negrita
        font-weight: bold;
        // añadir separacion entre letras
        letter-spacing: 0.1em;
        margin-left: 1.5rem;
    }
}