.cms {

    .cms-main {}

    &-section {
        margin-block: 3rem;
        padding-top: 1rem;
        border-top: 1px dashed #ddd;

        &-title {
            font-size: 1.5rem;
            font-family: $typo-primary;
            font-weight: 600;
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .item-seo {
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    }

    .gallery-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-gap: 2rem;
    }

    .gallery-item {
        position: relative;

        .img-fluid {
            aspect-ratio: 1;
            object-fit: cover;
            width: 100%;
        }

        .file-input {
            [role="presentation"] {
                padding: 3rem 20px !important;
            }
        }

        .input-file-remove {
            display: none;
        }

        &-title {
            padding: 1rem;
            font-size: 1.25rem;
            font-family: $typo-primary;
            font-weight: 600;
        }

        &-remove {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            font: 0/0 a;
            color: transparent;
            text-shadow: none;
            background-color: transparent;
            border: 0;
            width: 2rem;
            z-index: 1;

            &::before {
                content: '\00d7';
                font-size: 2rem;
                color: #fff;
                background-color: $danger;
                border-radius: 50%;
                padding: 0.5rem;
                cursor: pointer;
                height: 2rem;
                width: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                top: 0;
            }

            &:hover::before {
                background-color: lighten($danger, 10%);
            }
        }

        &-sort {
            position: absolute;
            left: 0.5rem;
            top: 0.5rem;
            display: flex;
            justify-content: center;
            gap: 0.5rem;
        }
    }
}

.dropdown-menu {
    @media (min-width: 992px) {
        top: 0 !important;
        transform: translateY(3rem) !important;
        left: 0 !important;
        right: 0 !important;
        background-color: $background;
        border: none;
        padding-inline: 2rem;
    }
}

.EntriesPage .rjsf .property-wrapper {
    display: flex;
    width: 100%;
    margin: 0 !important;

    .form-group {
        flex: 1;
    }
}