// Variable overrides first
$primary: darken(#9fbf98, 16%);
$secondary: #127475;
$accent: #F2542D;
$base: #042A2B;

$danger: #d9534f;

$background: #e7f5f2;
$border: rgba(73, 190, 170, 0.33);
$border-radius: 0.25rem;

$typo-primary: "Hanken Grotesk", sans-serif;

// Cambiar la variable de la fuente general a comic sans cursiva
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
$body-color: $base;

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/noty/src/noty.scss";
@import "../../node_modules/noty/src/themes/relax.scss";
@import "./modal.scss";
@import "./table.scss";
@import "./card.scss";
@import "./form.scss";
@import "./cms.scss";
@import "./filter.scss";
@import "./navbar.scss";
@import "./inputs.scss";

@import "./views/table";
@import "./views/edit";
@import "./views/login";

// @import '../modules/beeldit/documental-system/scss/documental-system.scss';
@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@300;600&display=swap");

:root {
  --sidebar-width: 260px;
  --navbar-height: 100px;
  --reduced-sidebar-width: 75px;
  --bs-blue: var(--bs-primary);
  --bs-letter-spacing: 0.06rem;
  --bs-letter-spacing: var(--bs-letter-spacing);
  --bs-body-color: #4d545a;
  --background: #f5f5f5;
}

@media (min-width: 1200px) {

  // :root {
  // TODO no entiendo porque entre 1200 y 1400 mide 80 el margin
  // --navbar-height: 80px;
  // }
}

@media (min-width: 1400px) {
  :root {
    --navbar-height: 100px;
  }
}

body {
  background-color: $background;
}

.admin {
  .page-content {
    position: relative;
    min-height: 100vh;
    padding: 2rem;
  }

  @media (min-width: 992px) {
    width: calc(100vw - var(--sidebar-width));
    margin-left: var(--sidebar-width);
  }

  @media (max-width: 992px) {
    width: calc(100vw - var(--reduced-sidebar-width));
    margin-left: var(--reduced-sidebar-width);
  }
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #fff;
  width: var(--sidebar-width);
  height: 100%;

  @media (max-width: 992px) {
    width: var(--reduced-sidebar-width);
  }
}

.nav-link:hover {
  color: var(--bs-link-hover-color) !important;
}

.hover-translate:hover {
  transition: transform 0.2s ease-out;
  transform: translateX(0.25rem);
}

.click:hover {
  cursor: pointer;
}

.sidebar .nav-item:last-child {
  position: absolute;
  bottom: var(--navbar-height);
  width: 100%;
}

/**
*  Start Filters styles
*/
.filters-container>.form-row>.property-wrapper {
  display: inline-flex;
  align-items: left;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

/**
*  End Filters styles
*/
.navbar .navbar-brand {
  margin-left: 1.5rem;
}

.card-header button.icon-button:not(:last-child) {
  margin-right: 1rem;
}

.modal-title,
.btn-close {
  color: white;
}

body {
  font-family: var(--bs-body-font-family) !important;
}

.nav-tabs {
  border: none !important;
}

.nav-item button {
  background-color: white;
}

.sidebar .nav-link.active {
  background-color: var(--bs-blue);
  border: none;
  color: var(--bs-white) !important;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.kpy {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media (max-width: 992px) {
  .kpy {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: none;
  }

  .col-span-2 {
    grid-column: span 1 / span 1;
  }
}

.kpy-item {
  border-radius: 0.5rem;
  background: rgba(231, 245, 242, 0.5);
}

.kpy-content {
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  align-items: flex-start;
}

.kpy-icon {
  margin-bottom: 1rem;
  color: $secondary;
}

.kpy-title {
  color: var(--bs-blue);
  font-weight: bold;
  font-size: 2.5rem;
}

.kpy-description {
  color: var(--bs-black);
  opacity: 0.75;
}

.button-action {
  width: 40px !important;
  height: 40px !important;
}

.text-right {
  text-align: right;
}

.error-detail li {
  list-style-type: none;
}

.error-detail {
  margin-top: 0.5rem;
  padding-left: 0px;
}

.btn-action {
  @extend .button-action;
  border-color: #fff;
  background-color: #fff;
  color: $accent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;

  &:hover {
    color: #fff;
    background-color: $accent;
    border-color: $accent;
  }
}

td.boolean,
th.boolean {
  text-align: center;
}

.w-95 {
  width: 95% !important;
}

.field-description {
  font-size: 0.75rem;
}

/* Auth */
.auth-panel {
  font-family: $typo-primary;

  .card {
    border-radius: 0;

    .card-body {
      margin: 2rem 5rem;

      .card-logo {
        width: 100%;
        text-align: center;
        margin-bottom: 2rem;

        img {
          width: 250px;
        }
      }
    }
  }

  button {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    background-color: var(--bs-black);
    border: none;
    height: 56px;
    border-radius: 0;

    &:hover {
      background-color: var(--bs-black);
    }
  }

  a {
    color: var(--bs-black);

    &:hover {
      color: var(--bs-black);
    }
  }

  input {
    border-radius: 0;
    height: 56px;
    border: 1px solid var(--bs-black);

    &:focus {
      border-color: var(--bs-black);
      box-shadow: none;
    }
  }
}

textarea.form-control {
  min-height: 5rem;
  field-sizing: content;
}

@media (max-width: 768px) {
  .auth-panel {
    font-family: $typo-primary;

    .card {
      width: 100%;
    }
  }
}

p {
  color: #000000;
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Rediseño 01/24
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.15rem;
  font-weight: 600;
  border-radius: 4rem;
  font-family: $typo-primary;
  font-size: 1.15rem;

  --bs-btn-padding-x: 1.25rem;
}

.btn-outline-primary {
  --bs-btn-hover-color: #fff;
  --bs-btn-active-color: #fff;
}

.btn-primary {
  color: #fff;
  background-color: $accent;
  border-color: $accent;

  &:hover {
    color: #fff;
    background-color: darken($accent, 10%);
    border-color: darken($accent, 10%);
  }
}

.table .button-action {
  padding: 0.25rem;
  background-color: transparent;
  border-color: transparent;
  color: $accent;

  &:hover {
    background-color: $accent;
    border-color: $accent;
    color: #fff;
  }
}

.action-button-text {
  @media (max-width: 1024px) {
    display: none;
  }
}

// Forms
.form-control,
.form-select,
.field .css-6snpu4-control {
  border-radius: var(--bs-border-radius);
  background-color: $background;
  border-color: $border;
  color: $base;

  &:hover {
    border-color: hsl(0, 0%, 70%);
  }

  &::placeholder {
    opacity: 0.5;
  }
}

@media (min-width: 992px) {
  .admin-navbar {
    display: none !important;
  }
}

@media (max-width: 992px) {
  #admin-panel {
    .page-content {
      margin-top: 100px;
    }
  }
}

// Admin panel
.admin {

  // Filters
  .filters-container {
    padding: 0;
    margin: 0;
  }

  // Sidebar
  .sidebar {
    padding: 0 !important;

    @media (min-width: 992px) {
      display: flex !important;
    }

    .navbar-brand {
      overflow: hidden;
      display: flex;
      padding: 1.5rem 2rem;

      img {
        max-width: 100%;
      }
    }

    .nav-item {
      padding-right: 1rem;
      margin-bottom: 0.85rem;

      &:last-child {
        position: relative;
        bottom: unset;
        margin-top: auto;
      }
    }

    .nav-link {
      font-family: $typo-primary;
      border-radius: 0 1.5rem 1.5rem 0;
      padding: 0.75rem 1rem 0.75rem 2rem;
      line-height: 1;
      margin-left: -0.5rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        opacity: 0.75;
      }
    }
  }

  // Tables
  .table-responsive {
    margin: 0 -1.5rem;
  }

  .table> :not(caption)>*>* {
    padding: 0.5rem 0.75rem;
    border-color: $border;
  }

  .table th {
    padding: 0.75rem 0.75rem;
  }

  .table thead th:first-child,
  .table tbody td:first-child {
    width: 3rem;
  }

  .table .number {
    text-align: right;
  }

  .table .action-column {
    padding-right: 1.5rem;
  }

  .pagination {
    margin: 0;

    &+br {
      display: none;
    }
  }

  // Tabs
  .nav-tabs {
    position: relative;
    z-index: 2;

    .nav-link {
      border: none;
      border-radius: 0.75rem 0.75rem 0 0;
      padding: 1.25rem 2rem;
      font-weight: 500;
      margin-right: 2px;
      background-color: #f3faf8;

      &.active {
        background-color: #fff;
        color: $primary;
      }

    }
  }

  .tab-content {
    position: relative;
    z-index: 1;

    .card {
      border-radius: 0 0.75rem 0.75rem 0.75rem;
    }
  }
}

.custom-checkbox {
  height: 20px;
  width: 20px;
}

.pe-hand {
  cursor: pointer;
}

.debug {
  background-color: yellow;
  color: #111;
  padding: 0.5em 1em;
  font-weight: 600;
  margin: 0;
  display: inline-block;
  max-width: 100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 8rem;
  padding: 1rem 1rem 3rem;
  box-sizing: border-box;
  background: #fff;
  filter: blur(5px) contrast(10) hue-rotate(300deg);
  mix-blend-mode: darken;
}

.loader:before,
.loader:after {
  content: '';
  grid-area: 1/1;
  width: 2rem;
  height: 2rem;
  background: #ffff00;
  animation: rotate 2s infinite;
}

.loader:after {
  animation-delay: -1s;
}

@keyframes rotate {
  0% {
    transform: translate(0, 0)
  }

  25% {
    transform: translate(100%, 0)
  }

  50% {
    transform: translate(100%, 100%)
  }

  75% {
    transform: translate(0, 100%)
  }

  100% {
    transform: translate(0, 0)
  }
}