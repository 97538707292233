.modal-header {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
    .modal-title {
        text-transform: uppercase;
        // texto en negrita
        font-weight: bold;
        // añadir separacion entre letras
        letter-spacing: 0.1em;
    }    
    .btn-close {
        padding: calc(var(--bs-modal-header-padding-y) * 1) calc(var(--bs-modal-header-padding-x) * 1) !important;
    }
}
    
  