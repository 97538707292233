.table-hover>tbody>tr:hover>* {
  --bs-table-bg-state: #{lighten($primary, 40%)};
}

.table-link {
  color: $primary;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.25em;
  }
}

.TablePagination {
  display: flex;
  justify-content: center;

  .page-link {
    min-width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;
  }
}

th.date,
td.date {
  @media (max-width: 768px) {
    display: none;
  }
}

.pagination .page-item {
  &:first-child .page-link {
    padding-left: 1.25rem;
    border-radius: 1.125rem 0 0 1.125rem;
  }

  &:last-child .page-link {
    padding-right: 1.25rem;
    border-radius: 0 1.125rem 1.125rem 0;
  }
}