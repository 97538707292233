.card {
    position: relative;
    border-radius: 0.75rem;
    background: #FFF;
    border: 0;
    filter: drop-shadow(0px 2.767px 2.214px rgba(0, 0, 0, 0.02)) drop-shadow(0px 6.65px 5.32px rgba(0, 0, 0, 0.03)) drop-shadow(0px 12.522px 10.017px rgba(0, 0, 0, 0.04)) drop-shadow(0px 22.336px 17.869px rgba(0, 0, 0, 0.04));
}

.card-header {
    padding: 1.5rem;
    border-radius: 0.75rem 0.75rem 0 0;
    border-color: $background;
    display: flex;
}

.card-header-actions {
    margin-left: auto;
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    justify-content: center;
}

.card-header-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: $primary;
    font-family: $typo-primary;

    @media (min-width: 768px) {
        line-height: 41.6px;
    }
}

.card-body {
    padding: 0 1.5rem 1.5rem;
}