.action-column {
  width: 10%; /* La columna se ajustará a su contenido */
  text-align: right;
}

td.boolean,
th.boolean {
  text-align: center;
}

.button-action {
    height: 40px!important;
    width: 40px!important;
    color: white;
}
.table-link {
  cursor: pointer;
  text-decoration: none;
}