.HouseDashboardPage {

  .btn-group>.btn {
    padding-inline: 1.25rem;
  }
}

.language-selector {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0;
}

.btn-icon {
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;
}

.actions-buttons {
  display: flex;
  gap: 0.25rem;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.file-input {
  @media (min-width: 768px) {
    min-height: 100px;
  }

  [role="presentation"] {
    p {
      opacity: 0.75;
      margin: 0;
      font-size: 90%;
    }
  }
}

.sections {
  .inputfile-container {
    display: flex;
    flex-flow: row-reverse;
    gap: 2rem;
    justify-content: flex-end;

    @media (max-width: 768px) {
      flex-flow: column;
      gap: 1rem;
    }

  }

  .sections-header-sort {
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    gap: 0.5rem;

    @media (min-width: 768px) {
      position: absolute;
      right: 1.5rem;
    }
  }

  .sections-header {
    padding-top: 1rem;
    border-top: 1px dotted #ddd;

    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }
  }

  .sections-header-title {
    font-size: 1rem;
    font-weight: 500;
  }

  .sections-content {}
}

.inputfile-container {

  .row {
    flex-direction: column-reverse;

    [class*="col-"] {
      width: 100%;
    }
  }

  span:not([class]) {
    display: none;
  }

  .inputfile-image {
    position: relative;

    .form-label {
      display: none;
    }
  }

  .inputfile-main {
    position: absolute;
    left: 0.5rem;
    bottom: 1rem;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  .file-input {
    position: relative;
    height: auto;
  }

  .input-file-remove {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    width: 2rem;

    &::before {
      content: '\00d7';
      font-size: 2rem;
      color: #fff;
      background-color: $danger;
      border-radius: 50%;
      padding: 0.5rem;
      cursor: pointer;
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:hover::before {
      background-color: lighten($danger, 10%);
    }
  }
}

.ReviewsPage .card-body,
.PagesPage .card-body,
.TableView {

  &>.rjsf {
    margin-block: 1rem;

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90px;
      margin: 0;
    }

    .form-group,
    .filters-container>.form-row>.property-wrapper {
      margin: 0;
      width: 100%;
    }

    .form-group {
      position: relative;

      &::after {
        content: '';
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"/><line x1="21" y1="21" x2="16.65" y2="16.65"/></svg>');
        background-size: 1.5rem;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translateY(-50%);
        width: 2rem;
        height: 2rem;
        opacity: 0.2;
      }
    }

    .form-control {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 2.5em 0.5rem 1rem;

      @media (min-width: 768px) {
        width: 18.75rem;
      }
    }
  }
}