.filters-container {
    padding-bottom: 1rem;
}
.filters-container > .form-row{
    text-align: right;
}
.filters-container > .form-row > .property-wrapper {
    display: inline-flex;
    align-items: left;
    margin-left: 1rem;
}