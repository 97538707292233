.custom-select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.css-1ulxeju-control {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: var(--bs-body-color) !important;
  background-color: var(--bs-body-bg) !important;
  background-clip: padding-box !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  appearance: none !important;
  border-radius: var(--bs-border-radius) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.css-1i2ta08-control {
  box-shadow: none !important;
  border-radius: var(--bs-border-radius) !important;
}

.css-1i2ta08-control,
.css-1i2ta08-control:hover,
.css-1i2ta08-control:active {
  color: var(--bs-body-color) !important;
  background-color: var(--bs-body-bg) !important;
  border-color: #99bdac !important;
  outline: 0 !important;
  border-radius: var(--bs-border-radius);
  box-shadow: 0 0 0 0.25rem rgba(50, 122, 88, 0.25) !important;
}