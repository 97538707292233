.form-group {
  margin-bottom: 0.85rem;
  flex-grow: 0;
  flex-shrink: 1;
  @media screen and (max-width: 515px) {
    .form-row {
      // display: flex !important;
      flex-direction: column !important;
      .property-wrapper {
        margin-left: 0;
        max-width: 100%;
      }
    }
  }
}
// .form-row {

// }
.control-label {
  font-size: 0.9rem;
}
.type-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-bottom: 4rem;
  .btn {
    height: auto !important;
  }
}
.form-group:has(.row) {
  margin-bottom: 0;
}